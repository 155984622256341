import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from '@deal/router'
import { useCurrentExpert } from '#src/app/context/expert'
import { useIdentityContext } from '#src/app/containers/Identity'
import { useStartScoutConversationFormMutation } from './StartScoutConversationForm.generated'
import { useAnimatedPlaceholderSuggestion } from './useAnimatedPlaceholderSuggestion'
import {
  StartScoutConversationTextField,
  StartScoutConversationTextFieldBaseProps
} from './TextField'

const DEFAULT_PLACEHOLDER = 'What are you looking for?'

interface StartScoutConversationFormProps extends StartScoutConversationTextFieldBaseProps {
  departmentId: string
  placeholderSuggestions?: string[]
  className?: string
}

export const StartScoutConversationForm: React.FC<StartScoutConversationFormProps> = ({
  departmentId,
  placeholderSuggestions,
  className,
  ...textFieldProps
}) => {
  const history = useHistory()
  const { setExpert } = useCurrentExpert()
  const { refetch: refetchIdentity = () => Promise.resolve() } = useIdentityContext()

  const [consumerMessage, setConsumerMessage] = useState('')

  const isPlaceholderSuggestionAnimated = !!placeholderSuggestions?.length && !consumerMessage
  const animatedPlaceholderSuggestion = useAnimatedPlaceholderSuggestion(placeholderSuggestions, {
    isDisabled: !isPlaceholderSuggestionAnimated
  })

  const [startAiGuestConversation] = useStartScoutConversationFormMutation({
    onCompleted: ({ startAiGuestConversation: { lead, myself, error } }) => {
      const { conversation, expert } = lead || {}
      if (!error && myself && conversation) {
        refetchIdentity().then(() => {
          setExpert(expert)
          history.push(`/inbox/${conversation.externalId}`)
        })
      } else if (error) {
        toast.error(error)
      }
    },
    onError: error => {
      toast.error(error.message)
    }
  })

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        if (consumerMessage) {
          startAiGuestConversation({
            variables: { departmentId, consumerMessage }
          })
        }
      }}
      className={className}
    >
      <StartScoutConversationTextField
        name="consumerMessage"
        value={consumerMessage}
        onChange={setConsumerMessage}
        placeholder={
          isPlaceholderSuggestionAnimated ? animatedPlaceholderSuggestion : DEFAULT_PLACEHOLDER
        }
        onFocus={
          isPlaceholderSuggestionAnimated
            ? () => setConsumerMessage(animatedPlaceholderSuggestion)
            : undefined
        }
        {...textFieldProps}
      />
    </form>
  )
}
