import React, { useMemo } from 'react'
import { useHistory } from '@deal/router'
import { Area, Button, Grid, Heading, Text } from '@deal/bluxome'
import { useIdentityContext } from '#src/app/containers/Identity'
import { YourRequestPost } from './YourRequestPost'
import styles from './styles.css'

interface HeroWithRequestPostProps {
  categoryName?: string
}

export const HeroWithRequestPost: React.FC<HeroWithRequestPostProps> = ({ categoryName }) => {
  const title = `Shop ${categoryName || 'products'} with the community`
  const history = useHistory()
  const { myself } = useIdentityContext()
  const requestPostId = myself?.user.mostRecentFeedRequestPost?.id

  const areas = `
    "mobileCopy mobileCopy mobileCopy mobileCopy mobileCopy mobileCopy"
    "mobileRequest mobileRequest mobileRequest mobileRequest mobileRequest mobileRequest"
    "mobileCta mobileCta mobileCta mobileCta mobileCta mobileCta"
  `

  const areasLg = `
    "desktopCopyCta desktopCopyCta desktopCopyCta desktopCopyCta desktopCopyCta spacer desktopRequest desktopRequest desktopRequest desktopRequest desktopRequest desktopRequest"
  `

  const copy = (
    <>
      <div className={styles.heading}>
        <Heading>{title}</Heading>
      </div>
      <div className={styles.subheading}>
        <Text style="large" color="neutrals-600">
          Experts will adjust recommendations based on your feedback, review your options and share
          thoughts.
        </Text>
      </div>
    </>
  )

  const cta = useMemo(() => {
    const totalRec =
      myself?.user.mostRecentFeedRequestPost?.productRecommendationsSummary?.totalCount || 0

    if (totalRec) {
      return (
        <div className={styles.recButton}>
          <Button
            onPress={() => {
              history.push(`/for-you/${requestPostId}`)
            }}
            color="learn-blue"
            fullWidth
          >
            View Recommendations
          </Button>
        </div>
      )
    }
    return (
      <div className={styles.viewButtonsContainer}>
        <Button
          onPress={() => {
            history.push(`/for-you?post=products`)
          }}
          color="learn-blue"
          fullWidth
        >
          Browse all recommendations
        </Button>
        <Button
          onPress={() => {
            history.push(`/for-you/${requestPostId}`)
          }}
          color="learn-blue"
          style="outline"
          fullWidth
        >
          View my request
        </Button>
      </div>
    )
  }, [])

  return (
    <div>
      <Grid areas={areas} areasLg={areasLg}>
        <Area name="mobileCopy" hideAbove="lg">
          <div className={styles.textSpacing}>{copy}</div>
        </Area>
        <Area name="desktopCopyCta" hideBelow="lg">
          <div className={styles.heroContentDesktop}>
            {copy}
            {cta}
          </div>
        </Area>
        <Area name="desktopRequest" hideBelow="lg">
          <YourRequestPost categoryName={categoryName || 'product'} />
        </Area>
        <Area name="mobileRequest" hideAbove="lg">
          <div className={styles.areaSpacing}>
            <YourRequestPost categoryName={categoryName || 'product'} />
          </div>
        </Area>
        <Area name="mobileCta" hideAbove="lg">
          <div className={styles.areaSpacing}>{cta}</div>
        </Area>
      </Grid>
    </div>
  )
}
