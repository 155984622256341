import React from 'react'
import { AriaTextFieldProps, mergeProps, useFocusRing, useTextField } from 'react-aria'
import classNames from 'classnames'
import { Button, ButtonColor, ButtonSize, Color, Text } from '@deal/bluxome'
import { useForwardedRef } from '#src/app/hooks/useForwardedRef'
import SearchIcon from './search.svg'
import styles from './styles.css'

const TextFieldSize: Record<ButtonSize, string> = {
  small: '32px',
  base: '44px',
  large: '56px'
}

const IconSize: Record<ButtonSize, string> = {
  small: '16px',
  base: '20px',
  large: '26px'
}

const IconColor: Record<ButtonColor, Color> = {
  'neutral-dark': 'neutrals-black',
  'neutral-light': 'neutrals-600',
  'learn-blue': 'learn-blue-400',
  'shop-orange': 'shop-orange-400'
}

export interface StartScoutConversationTextFieldBaseProps {
  /** @default base */
  size?: ButtonSize

  /**
   * The border color of the text field
   * Use null for transparent border
   * @default neutrals-black
   */
  borderColor?: Color | null

  /**
   * The color of the 1px drop-shadow applied when input is focused
   * If not included, matches borderColor
   * @default neutrals-black
   */
  focusRingColor?: Color

  /** @default neutrals-600 */
  placeholderColor?: Color

  /** @default { label: null, color: neutrals-black } */
  submitButton?: {
    label: Extract<React.ReactNode, string | null>
    color: ButtonColor
  }
}

interface StartScoutConversationTextFieldProps
  extends StartScoutConversationTextFieldBaseProps,
    AriaTextFieldProps {}

export const StartScoutConversationTextField = React.forwardRef<
  HTMLInputElement,
  StartScoutConversationTextFieldProps
>((props, ref) => {
  const {
    size = 'base',
    borderColor = 'neutrals-black',
    focusRingColor = borderColor || 'neutrals-black',
    placeholderColor = 'neutrals-600',
    submitButton = { label: null, color: 'neutral-dark' },
    ...textFieldProps
  } = props
  const inputRef = useForwardedRef(ref)
  const { inputProps } = useTextField(
    { ...textFieldProps, 'aria-label': 'consumer message input', inputElementType: 'input' },
    inputRef
  )
  const { focusProps, isFocused } = useFocusRing({ isTextInput: true })
  return (
    <div
      className={classNames(styles.textField, {
        [styles.withButton]: submitButton.label != null
      })}
      style={
        {
          '--size-prop-value': TextFieldSize[size],
          '--border-color-prop-value': !borderColor ? 'transparent' : `var(--color-${borderColor})`,
          '--focus-ring-color-prop-value': `var(--color-${focusRingColor})`,
          '--placeholder-color-prop-value': `var(--color-${placeholderColor})`
        } as React.CSSProperties
      }
    >
      <div className={classNames(styles.inputWrapper, { [styles.focused]: isFocused })}>
        <Text as="div" color="neutrals-black">
          <input ref={ref} className={styles.input} {...mergeProps(inputProps, focusProps)} />
        </Text>
        <button
          className={styles.searchButton}
          type="submit"
          aria-label="submit search"
          disabled={!textFieldProps.value}
        >
          <Text as="span" color={IconColor[submitButton.color]}>
            <SearchIcon width={IconSize[size]} height={IconSize[size]} />
          </Text>
        </button>
      </div>
      {submitButton.label && (
        <Button style="filled" color={submitButton.color} size={size} type="submit" fullWidth>
          {submitButton.label}
        </Button>
      )}
    </div>
  )
})

StartScoutConversationTextField.displayName = 'StartScoutConversationTextField'
