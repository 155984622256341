import React from 'react'
import classnames from 'classnames'
import { useHistory } from '@deal/router'
import { Avatar, FixedAspectRatioImage } from '@deal/components'
import { Heading, Rounded, Text } from '@deal/bluxome'
import { FeedItemForFeedAuthorFragment } from '#src/app/routes/for-you/components/FeedAuthor/FeedAuthor.generated'
import { useBreakpoint } from '#src/app/hooks/useBreakpoint'
import { useIdentityContext } from '#src/app/containers/Identity'
import ExpertAvatarLegacy from '#src/app/components/ExpertAvatarLegacy'
import InfoIcon from './info.svg'
import LinkIcon from './clip.svg'
import AsteriskIcon from './asterisk.svg'
import styles from './styles.css'

interface YourRequestPostProps {
  categoryName: string
}

const stripWww = (url: string) => {
  const prefixIndex = url.indexOf('www.')
  if (prefixIndex === -1) {
    return url
  }
  return url.substring(prefixIndex + 4)
}

export const YourRequestPost: React.FC<YourRequestPostProps> = ({ categoryName }) => {
  const { myself } = useIdentityContext()
  const history = useHistory()
  const requestPost = myself?.user.mostRecentFeedRequestPost
  const attributes = requestPost?.additionalContent.find(
    c => c.__typename === 'AttributesFeedContentBlock'
  )
  const communityRecommendations = requestPost?.productRecommendationsSummary
  const isRecExceeded = (communityRecommendations?.totalCount || 0) > 4

  const handleProductClick = () => {
    history.push(`/for-you/${requestPost?.id}`)
  }

  return (
    <Rounded bgColor="expertise-neon-400">
      <div className={styles.requestContainer}>
        <div className={styles.requestHeading}>
          <Heading size="h2">Your {categoryName} request</Heading>
        </div>
        <div className={styles.pillContainer}>
          {attributes?.__typename === 'AttributesFeedContentBlock' &&
            attributes.attributes
              .map(attr => attr.formatted!)
              .filter(attr => attr)
              .map(attr => (
                <Text key={attr.internalName} style="small">
                  <div className={styles.pill}>
                    <span>{attr.name}: </span>
                    <span
                      className={styles.attrValue}
                      dangerouslySetInnerHTML={{ __html: attr.displayHtml }}
                    />
                  </div>
                </Text>
              ))}
        </div>
        {!communityRecommendations?.totalCount && (
          <div className={styles.infoContainer}>
            <InfoIcon />
            <div className={styles.infoTextContainer}>
              <Text style="base" color="neutrals-black">
                No recommendations yet
              </Text>
              <Text style="small" color="neutrals-600">
                The expert community hasn’t added any recommendations so far, we’ll let you know
                when there’s an updated.
              </Text>
            </div>
          </div>
        )}
        {!!communityRecommendations?.totalCount && (
          <>
            <div className={styles.recText}>
              <AsteriskIcon />
              {communityRecommendations?.totalCount} recommendation
              {communityRecommendations?.totalCount !== 1 ? 's' : ''} by the community
            </div>
            <div className={styles.images}>
              {communityRecommendations?.items.slice(0, 4).map((item, index) => {
                if (isRecExceeded && index === 3) {
                  return (
                    <div
                      className={classnames(styles.imageContainer, styles.rounded)}
                      key={item.id}
                      onClick={handleProductClick}
                    >
                      <div className={styles.textContainer}>
                        <Text style="small-medium-tight">
                          + {communityRecommendations.totalCount - 3} more
                        </Text>
                      </div>
                    </div>
                  )
                }
                const imageUrl =
                  item.product.__typename === 'CuratedProductFeedContentBlockItem'
                    ? item.product.sellable.primaryImage?.url
                    : item.product.externalProductImageMedia?.url

                const externalUrl =
                  item.product.__typename === 'ExternalProductFeedContentBlockItem' &&
                  item.product.url

                return (
                  <div
                    key={item.id}
                    className={classnames(styles.imageContainer, styles.rounded)}
                    onClick={handleProductClick}
                  >
                    {imageUrl ? (
                      <FixedAspectRatioImage
                        aspectRatio={1}
                        src={imageUrl}
                        className={styles.image}
                        size="25vw"
                        contentClassName={styles.rounded}
                      />
                    ) : (
                      <div className={styles.externalUrlContainer}>
                        <LinkIcon />
                        {externalUrl && (
                          <Text style="small-medium-tight">
                            <div className={styles.externalUrl}>
                              {stripWww(new URL(externalUrl).hostname)}
                            </div>
                          </Text>
                        )}
                      </div>
                    )}
                    <div className={styles.avatarWrapper}>
                      {item.sourceComment && <FeedAuthorAvatar feedItem={item.sourceComment} />}
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
    </Rounded>
  )
}

const FeedAuthorAvatar: React.FC<{ feedItem: FeedItemForFeedAuthorFragment }> = ({ feedItem }) => {
  const isMobile = !useBreakpoint('lg', 'bluxome')
  return feedItem.author.__typename === 'FeedAuthorExpert' ? (
    <ExpertAvatarLegacy
      expert={feedItem.author.expert}
      size={isMobile ? 24 : 32}
      className={styles.avatar}
    />
  ) : (
    <Avatar
      name={feedItem.author.name}
      size={isMobile ? 24 : 32}
      className={styles.avatar}
      src={
        feedItem.author.__typename === 'FeedAuthorConsumer'
          ? feedItem.author.profilePhoto?.url
          : undefined
      }
    />
  )
}
