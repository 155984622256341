import { useEffect, useRef, useState } from 'react'

// How long it takes on average to type one character (ms)
const TYPING_STEP = 60
// How long the fully-typed suggestion is visible before deleting (ms)
const DELAY_AFTER_TYPING = 4_000

export function useAnimatedPlaceholderSuggestion(
  suggestions: string[] = [],
  opts = { isDisabled: false }
) {
  const [cursor, setCursor] = useState(0)
  const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0)
  const [typingDirection, setTypingDirection] = useState<'forward' | 'backward' | null>('forward')
  const timeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    const currentSuggestion = suggestions[currentSuggestionIndex]

    if (opts.isDisabled || !currentSuggestion) {
      return
    }

    const maxCursor = currentSuggestion.length

    const handleTyping = () => {
      timeoutRef.current = setTimeout(() => {
        setCursor(prev => {
          const nextCursor = prev + 1
          if (nextCursor >= maxCursor) {
            // Setting null direction signals the DELAY_AFTER_TYPING period,
            // after which the direction is switched to backward
            setTypingDirection(null)
          } else {
            handleTyping()
          }
          return nextCursor
        })
        // Simulate randomness for typing forwards
      }, TYPING_STEP / 2 + Math.random() * TYPING_STEP)
    }

    const handleDeleting = () => {
      timeoutRef.current = setTimeout(() => {
        setCursor(prev => {
          // Deleting is 3x faster than typing forwards, without the randomness
          const nextCursor = Math.max(0, prev - 3)
          if (nextCursor <= 0) {
            setCurrentSuggestionIndex(i => (i + 1) % suggestions.length)
            setTypingDirection('forward')
          } else {
            handleDeleting()
          }
          return nextCursor
        })
      }, TYPING_STEP)
    }

    switch (typingDirection) {
      case 'forward':
        timeoutRef.current = setTimeout(handleTyping, 0)
        break

      case 'backward':
        timeoutRef.current = setTimeout(handleDeleting, 0)
        break

      case null:
        timeoutRef.current = setTimeout(() => setTypingDirection('backward'), DELAY_AFTER_TYPING)
        break
    }

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [opts.isDisabled, suggestions, currentSuggestionIndex, typingDirection])

  return opts.isDisabled
    ? suggestions[currentSuggestionIndex]
    : suggestions[currentSuggestionIndex].slice(0, cursor)
}
